.example-print {
    display: none;
}

@media print {
    body {
        visibility: hidden;
    }
    .printable {
        visibility: visible;
        position: fixed;
        min-width: 600px;
        top: 0;
        left: 0;
        padding: 10mm;
    }

    .printable * {
        visibility: visible;
    }
}
